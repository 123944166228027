import React, { useEffect } from 'react';
import { Formik } from 'formik';
import {
  FormikValidator, Submit, FormikSideEffects, FormikPill, FormikTextBox,
  FormikDateTextBox, FormikMobilePhone, FormikCheckbox, FormikAddressSearch
} from '../../../shared';
import { toPillItems } from '../../../shared/form/pill';
import { required, maxCharLength, alphaSpacesHyphensApostrophesOnly, noDuplicateSpecialChars, beginningAlpha, minAge, maxAge, reggex, email, mustBeTrueRequired, dateNotFuture, requiredWithMessage, date } from '../../../shared/form/formik/validators';
import { ValidatorType } from '../../../shared/form/formik/formik-validator';
import { standardAddressFieldValidators, AddressFormValues } from '../../../shared/form/formik/formik-address-search/address-search-service';
import { SubmitPersonalDetailsRequest } from '../../../../api/loan-application/api models';
import { nameAndId } from '../../../shared/form/formik/formik-helper';
import { PersonalDetailsPrefill } from '../../../../utils/LocalFormPrefill';

interface PersonalDetailsInitialFormProps {
  onSubmit: (values: SubmitPersonalDetailsRequest) => void
  submitting?: boolean
  onBack?: () => void
  initialValues?: PersonalDetailsFormValues
}

export interface PersonalDetailsFormValues extends SubmitPersonalDetailsRequest, AddressFormValues { }

const SubmitPersonalDetailsForm: React.FC<PersonalDetailsInitialFormProps> = (props) => {

  const sharedNameValidators: ValidatorType<any, any>[] = [
    alphaSpacesHyphensApostrophesOnly,
    noDuplicateSpecialChars,
    beginningAlpha('Please ensure your name begins with an A-Z character')
  ];


  const validator = new FormikValidator<PersonalDetailsFormValues, PersonalDetailsInitialFormProps>({
    fields: [
      { name: 'title', validators: [required] },
      { name: 'firstName', validators: [requiredWithMessage("Please specify your first name."), maxCharLength(40), ...sharedNameValidators] },
      { name: 'middleName', validators: [maxCharLength(80), ...sharedNameValidators] },
      { name: 'surname', validators: [requiredWithMessage("Please specify your last name."), maxCharLength(50), ...sharedNameValidators] },
      { name: 'dateOfBirth', validators: [required, date('DD/MM/YYYY'), maxCharLength(50), dateNotFuture('DD/MM/YYYY'), minAge(18), maxAge(80)] },
      { name: 'mobile', validators: [required, reggex(/^\s*0\s*[45]\s*(\d\s*){8}$/, 'Please enter a valid mobile number in the format 0___ ___ ___')] },
      { name: 'email', validators: [required, email, maxCharLength(254)] },
      { name: 'consent', validators: [mustBeTrueRequired('Please agree to the terms and conditions in order to continue')] },
      ...standardAddressFieldValidators
    ]
  });

  const consentLabel = 'I have reviewed, understand and accept the terms of the <a href="https://www.cashconverters.com.au/resources/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>, the <a href="https://www.cashconverters.com.au/disclosure-acknowledgement" target="_blank" rel="noopener">Acknowledgement and Consent</a> and the <a href="https://www.cashconverters.com.au/resources/terms-conditions" target="_blank" rel="noopener">CC Statement Terms and Conditions</a>.';
  const preMarketingLabelText = "At Cashies, we’re all for our customers – that’s why we only want to contact you about things that matter to you. If you want to know about what Cashies can offer you now and in the future, including invitations to apply for, and offers to enter into, credit contracts and other products and services like small and medium amount credit contracts, or a line of credit, simply select the box below. If you don’t, no hard feelings. Just hit ‘next’ to move your application forward";
  const marketingConsentLinkText = "Click here for more information.";
  const marketingConsentLink = "https://www.cashconverters.com.au/terms-conditions/marketing-consent";
  const marketingLabel = 'By providing my details to Cash Converters, I am consenting to be contacted by telephone or email about offers to enter into, and invitations to apply for, any of Cash Converters’ credit contracts and products, including small amount credit contracts.';

  const formId = 'submit-personal-details-form';
  return (
    <Formik
    initialValues={validator.getInitial(props.initialValues)}
      onSubmit={props.onSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <form id={formId} onSubmit={formikProps.handleSubmit}>
            <FormikPill {...nameAndId<PersonalDetailsFormValues>('title')} items={toPillItems(['Mr', 'Mrs', 'Miss', 'Ms'])} label='Title' formikProps={formikProps} />
            <FormikTextBox {...nameAndId<PersonalDetailsFormValues>('firstName')} label='First name (as per your ID)' formikProps={formikProps} />
            <FormikTextBox {...nameAndId<PersonalDetailsFormValues>('middleName')} label='Middle name (as per your ID, if applicable)' formikProps={formikProps} />
            <FormikTextBox {...nameAndId<PersonalDetailsFormValues>('surname')} label='Last name (as per your ID)' formikProps={formikProps} />
            <FormikDateTextBox placeholder='DD/MM/YYYY' {...nameAndId<PersonalDetailsFormValues>('dateOfBirth')} label='Date of birth' formikProps={formikProps} />
            <FormikAddressSearch label='Residential address' formikProps={formikProps} />
            <FormikMobilePhone {...nameAndId<PersonalDetailsFormValues>('mobile')} placeholder='0___ ___ ___' label='Mobile number' formikProps={formikProps} />
            <FormikTextBox type='email' {...nameAndId<PersonalDetailsFormValues>('email')} label='Email' formikProps={formikProps} />
            <FormikCheckbox {...nameAndId<PersonalDetailsFormValues>('consent')} label={consentLabel} formikProps={formikProps} />
            <label style={{textAlign: 'justify', display: 'inline-block'}}>
              <span>{preMarketingLabelText} </span>
              <a href={marketingConsentLink} target="_blank" rel="noopener"> {marketingConsentLinkText}</a>
            </label>
            <FormikCheckbox {...nameAndId<PersonalDetailsFormValues>('marketing')} label={marketingLabel} formikProps={formikProps} />
            <Submit disabled={props.submitting} id='submit-personal-details-submit' onSubmit={formikProps.handleSubmit} text='Next' />
            <FormikSideEffects formId={formId} dontFocusFirstInputOnInit />
          </form>
        );
      }}
    />
  );
};

export default SubmitPersonalDetailsForm;